import React, { useEffect } from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../layouts/TemplateColumn';
import { PageHeader, PageSection, PageSubSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, PrimaryClaim, SecondaryCallout, KMCurve, NCCNcallout, ReferencesBlock, ForestPlot, FeaturedStat, TextBlock, DataTable, ComparisonTable, EndpointDisplay, PatientProfile} from '../../components';
import { StaticImage } from 'gatsby-plugin-image';
import MEL_ADJUVANT_2 from '../../components/StudyDesign/__study-design/mel-adjuvant-stage-two'
import MEL_ADJUVANT from '../../components/StudyDesign/__study-design/mel-adjuvant' //stage 3
import { jobCodes } from '../../util/data/jobCodes';
import recurrenceTable from '../../assets/MELA-217379_ROR-Concept_chart_L18_mobile.png';
import { createMarkup } from '../../util/functions';
import { Heading } from '../../util/typography';

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-054 & KEYNOTE-716 - Clinical Trial Results | HCP`,
    keywords: `keynote 054, keynote 716, adjuvant treatment of melanoma clinical trial results`,
    description: `Health care professionals may find clinical trial results for KEYNOTE-054 and KEYNOTE-716 for the adjuvant treatment of certain patients with melanoma.`,
    schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/melanoma-adjuvant-therapy/","@type":"MedicalTrial","name":"Clinical Findings from KEYNOTE-716 (Stage IIB/IIC)","description":"Significant Improvement in Recurrence-Free Survival (RFS) Demonstrated With KEYTRUDA vs Placebo","trialDesign":"A retrospective review of 738 adult patients from a prospectively maintained, single-institution database, with resected pathologic stage II primary cutaneous melanoma (AJCC 7th ed.). All patients were treated at Memorial Sloan Kettering Cancer Center between January 1993 and December 2013. Patients underwent pathological nodal staging by sentinel lymph node biopsy or elective lymph node dissection. Median follow-up of patients with stage IIB and stage IIC melanoma was 50.2 and 46.2 months, respectively."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/melanoma-adjuvant-therapy/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for U.S. health care professionals."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/melanoma-adjuvant-therapy/","@type":"ApprovedIndication","name":"KEYTRUDA®","alternateName":"pembrolizumab","description":"KEYTRUDA is indicated for the adjuvant treatment of adult and pediatric (12 years and older) patients with stage IIB, IIC, or III melanoma following complete resection."}`    
    ]
}

//from /src/util/data/indications.js
const indicationId = 3;
const jobCode = jobCodes[2].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections: [
        {
            heading: "Additional Clinical Data",
            items: [
                { text: 'Advanced Melanoma', url: '/efficacy/melanoma-monotherapy/' },
            ]
        },
        {
            heading: "Related Information",
            items: [
                { text: 'Selected Adverse Reactions', url: '/safety/adverse-reactions/melanoma-adjuvant-therapy/' },
                { text: 'Dosing', url: '/dosing/options/' },
            ]
        },
    ],
    interestLinks: {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    { label: 'Clinical Findings from KEYNOTE&#8288;-&#8288;716 (Stage IIB/IIC)' },
    { label: 'Study Design for KEYNOTE&#8288;-&#8288;716 (Stage IIB/IIC)' },
    { label: 'Stage IIB/IIC Melanoma: Risk of Recurrence from a Retrospective Study' },
    { label: 'NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®)' }, 
    { label: 'Clinical Findings from KEYNOTE&#8288;-&#8288;054 (Stage III)' }, 
    { label: 'Study Design for KEYNOTE&#8288;-&#8288;054 (Stage III)' },
    { label: 'KEYNOTE&#8288;-&#8288;054 Recurrence Rates' },
    { label: 'Hypothetical Patient Cases' },
];

const kn716InterimOneTableFootnotes = [
    {
        label: "a.",
        text: "Based on the stratified Cox proportional hazard model."
    },
    {
        label: "b.",
        text: "Based on log-rank test stratified by AJCC, 8th edition stage."
    },
    {
        label: "c.",
        text: "<i>P</i>-value is compared with 0.0202 of the allocated alpha for this interim analysis."
    },
]

const kn716KMCurveFootnotes = [
    {
        label: "d.",
        text: "Based on Cox regression model with Efron’s method of tie handling with treatment as a covariate stratified by melanoma T Stage (T3b vs T4a vs T4b)."
    },
];

const kn716DmfsTableFootnotes = [
    {
        label: "e.",
        text: "DMFS was defined as the time from randomization to the first diagnosis of distant metastasis.<sup>7</sup> Distant metastasis is defined as a spread of tumor to distant organs or distant lymph nodes."
    },
    {
        label: "f.",
        text: "As defined in American Joint Committee on Cancer (AJCC) 8th edition, the presence of distant metastases indicates stage IV disease.<sup>5</sup>"
    },
    {
        label: "g.",
        text: "Based on the stratified Cox proportional hazard model."
    },
    {
        label: "h.",
        text: "Based on a a log-rank test stratified by AJCC 7th edition stage."
    },
    {
        label: "i.",
        text: "<i>P</i>-value is compared with 0.0256 of the allocated alpha for this interim analysis."
    },
];

const kn716DmfsKMFootnotes = [
    {
        label: "j.",
        text: "Based on Cox regression model with Efron’s method of tie handling with treatment as a covariate stratified by melanoma T Stage (T3b vs T4a vs T4b).<sup>4</sup>"
    },
];

const kn716RORTableFootnotes = [
    
    {
        label: "k.",
        text: "Some sites of metastasis have been combined because of data privacy rules requiring masking of numbers ≤ 5."
    },
];

const NCCNcalloutData = {
    title: 'NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®): An NCCN Category 1 Option for Melanoma in Certain Adjuvant Treatment Settings<sup>9</sup>',
    bodyCopy: `
        <h5>Recommended by the National Comprehensive Cancer Network® (NCCN®) for Eligible Patients With Stages IIB/IIC or Stage III Melanoma<sup>9</sup></h5>
        <p>Stage IIB and IIC melanoma: NCCN Guidelines® recommend pembrolizumab (KEYTRUDA) as an option for the adjuvant treatment of patients with pathological stage IIB or IIC cutaneous melanoma (category 1).<sup>9,l-n</sup></p>
        <p>Stage III melanoma: NCCN Guidelines® recommend pembrolizumab (KEYTRUDA) as an option for the adjuvant treatment of select patients with stage III cutaneous melanoma with resectable clinically positive nodes (category 1 and preferred).<sup>9,o</sup></p>
    `,
    showBadge: false,
};

const NCCNFootnotes = [
    {
        label: "l.",
        text: "Adjuvant programmed cell death protein 1 (PD-1) therapy is approved for clinical or pathologic stage IIB and IIC melanoma. Pathologic staging (with SLNB) is strongly recommended prior to consideration of adjuvant pembrolizumab—to enhance risk/benefit patient discussions and optimize local/regional disease control."
    },
    {
        label: "m.",
        text: "For patients with stage IIB/IIC disease being considered for adjuvant therapy, baseline/pretreatment imaging is appropriate."
    },
    {
        label: "n.",
        text: "Adjuvant pembrolizumab reduces relapse events for resected pathologic stage IIB and IIC melanoma; longer follow up is needed to assess impact on OS. Clinicians considering adjuvant pembrolizumab therapy for stages IIB or IIC disease should have a detailed discussion with the patient (shared decision making) to weigh the pros and cons of treatment benefit versus toxicity. Factors to be considered include patient’s age, performance status, personal/family history of autoimmune disease, absolute risk of recurrence, and risk of long-term immunotoxicities including approximately 15% risk of lifelong endocrine dysfunction.<sup>2</sup>"
    },
    {
        label: "o.",
        text: "Pembrolizumab has shown clinically significant improvement in RFS compared to placebo, but its impact on OS has not yet been reported."
    },
];

const NCCNdefinitions = `
    <p>NCCN makes no warranties of any kind whatsoever regarding their content, use, or application and disclaims any responsibility for their application or use in any way.</p>
    <p>Category 1 = Based upon high-level evidence (≥1 randomized phase 3 trials or high-quality, robust meta-analyses), there is uniform NCCN consensus (≥85% support of the Panel) that the intervention is appropriate.<sup>9</sup></p>
    <p>SLNB = sentinel lymph node biopsy; OS = overall survival.</p>
`;

const NCCNcalloutReferences = [
    {
        label: "9.",
        text: "Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Cutaneous Melanoma V.2.2025. National Comprehensive Cancer Network, Inc. 2024. All rights reserved. Accessed January 29, 2025. To view the most recent and complete version of the guidelines, go online to NCCN.org."
    },
];

const kn054RFSTableFootnotes = [
    {
        label: "p.",
        text: "Based on the stratified Cox proportional hazard model."
    },
    {
        label: "q.",
        text: "Stratified by AJCC 7th edition stage."
    },
    {
        label: "r.",
        text: "<i>P</i>-value is compared with 0.016 of the allocated alpha for this interim analysis."
    },
];

const kn054RFSKMFootnotes = [
    {
        label: 's.',
        text: 'Based on Cox regression model with treatment as a covariate stratified by stage (IIIA [>1&nbsp;mm metastasis] vs. IIIB vs. IIIC 1-3 nodes vs. IIIC = 4 nodes) as indicated at randomization.<sup>11</sup>'
    },
];


const kn054DMFSKMFootnotes = [
    {
        label: "t.",
        text: "DMFS was defined as the time from randomization to the first diagnosis of distant metastasis or date of death (whatever the cause), whichever occurred first.<sup>14</sup> Distant metastasis is defined as a spread of tumor to distant organs or distant lymph nodes."
    },
    {
        label: "u.",
        text: "As defined in AJCC 7th edition, the presence of distant metastases indicates stage IV disease."
    },
    {
        label: "v.",
        text: "Based on the stratified Cox proportional hazard model."
    },
    {
        label: "w.",
        text: "Stratified by AJCC 7th edition stage."
    },
    {
        label: "x.",
        text: "<i>P</i>-value is compared with 0.028 of the allocated alpha for this analysis."
    },
    {
        label: "y.",
        text: "Based on a log-rank test stratified by AJCC 7th edition stage."
    },
];

const recurrenceTableFootnotes = [
    {
        label: "z.",
        text: "Staging according to AJCC 7th ed."
    },
    {
        label: "aa.",
        text: "Staging according to AJCC 8th ed."
    },
];

const recurrenceTableAlt = "KEYNOTE-054 Recurrence Rates at 3-Year Analysis";

const pageReferences = [
    {
        label: "1.",
        text: "Opdivo. Package Insert. Bristol-Myers Squbb Company; 2024."
    },
    {
        label: "2.",
        text: "Luke JJ, Rutkowski P, Queirolo P, et al. Pembrolizumab versus placebo as adjuvant therapy in completely resected stage IIB or IIC melanoma (KEYNOTE-716): a randomised, double-blind, phase 3 trial. <i>Lancet.</i> 2022;399(10336):1718–1729. doi:10.1016/S0140-6736(22)00562-1"
    },
    {
        label: "3.",
        text: "Data available on request from the Merck National Service Center via email at daprequests@merck.com. Please specify information package US-OOC-02729."
    },
    {
        label: "4.",
        text: "Luke JJ, Ascierto PA, Khattak MA, et al. Pembrolizumab versus placebo as adjuvant therapy in resected stage IIB or IIC melanoma: Final analysis of distant metastasis-free survival in the phase III KEYNOTE-716 study. <i>J Clin Oncol</i>. 2024;42(14):1619–1624. doi:10.1200/JCO.23.02355"
    },
    {
        label: "5.",
        text: "Keung E, Gershenwald J. The eighth edition American Joint Committee on Cancer (AJCC) melanoma staging system: implications for melanoma treatment and care. <i>Expert Rev Anticancer Ther.</i> 2018;18(8):775–784. doi:10.1080/14737140.2018.1489246"
    },
    {
        label: "6.",
        text: "Long GV, Luke JJ, Khattak MA, et al. Pembrolizumab versus placebo as adjuvant therapy in resected stage IIB or IIC melanoma (KEYNOTE-716): distant metastasis-free survival results of a multicentre, double-blind, randomised, phase 3 trial. <i>Lancet Oncol.</i> 2022;23(11):1378-1388. doi: 10.1016/S1470-2045(22)00559-9"
    },
    {
        label: "7.",
        text: "Luke JJ, Rutkowski P, Queirolo P, et al. Supplementary Appendix to: Pembrolizumab versus placebo as adjuvant therapy in completely resected stage IIB or IIC melanoma (KEYNOTE-716): a randomised, double-blind, phase 3 trial. <i>Lancet.</i> 2022;399(10336):1718–1729. doi:10.1016/S0140-6736(22)00562-1"
    },
    {
        label: "8.",
        text: "Samlowski W, Silver MA, Hohlbauch A, et al. Real-world clinical outcomes of patients with stage IIB or IIC cutaneous melanoma treated at US community oncology clinics. <i>Future Oncol.</i> 2022;18(33):3755-3767. doi:10.2217/fon-2022-0508"
    },
    {
        label: "9.",
        text: "Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Cutaneous Melanoma V.2.2025. National Comprehensive Cancer Network, Inc. 2024. All rights reserved. Accessed January 29, 2025. To view the most recent and complete version of the guidelines, go online to NCCN.org."
    },
    {
        label: "10.",
        text: "Eggermont AMM, Blank CU, Mandala M, et al. Adjuvant pembrolizumab versus placebo in resected stage III melanoma. <i>N Engl J Med.</i> 2018;378(19):1789–1801."
    },
    {
        label: "11.",
        text: "Data available on request from the Merck National Service Center via email at daprequests@merck.com. Please specify information package US-OOC-02442."
    },
    {
        label: "12.",
        text: "Eggermont AMM, Kicinski M, Blank CU, et al. Five-Year analysis of adjuvant pembrolizumab or placebo in stage iii melanoma. <i>NEJM Evid.</i> 2022;1(1):1-12. doi:10.1056/EVIDe2100015"
    },
    {
        label: "13.",
        text: "Eggermont AMM, Blank CU, Mandalà M, et al. Adjuvant pembrolizumab versus placebo in resected stage III melanoma (EORTC 1325-MG/KEYNOTE-054): distant metastasis-free survival results from a double-blind, randomised, controlled, phase 3 trial. <i>Lancet Oncol.</i> 2021;22(5):643-654. doi:10.1016/S1470-2045(21)00065-6"
    },
    {
        label: "14.",
        text: "Study of pembrolizumab (MK-3475) versus placebo after complete resection of high-risk stage III melanoma (MK-3475-054/1325-MG/KEYNOTE-054). Clinical Trials.gov. Updated August 21, 2023. Accessed November 11, 2024. https://classic.clinicaltrials.gov/ct2/show/NCT02362594?term=Nct02362594&draw=2&rank=1"
    },
    {
        label: "15.",
        text: "Eggermont AMM, Blank CU, Mandala M, et al. Supplementary Appendix to: Adjuvant pembrolizumab versus placebo in resected stage III melanoma. <i>N Engl J Med.</i> 2018;378(19):1789-1801. doi:10.1056/NEJMoa1802357."
    },
    {
        label: "16.",
        text: "Eggermont AMM, Blank CU, Mandala M, et al. Longer follow-up confirms recurrence-free survival benefit of adjuvant pembrolizumab in high-risk stage III melanoma: updated results from the EORTC 1325-MG/KEYNOTE&#8288;-&#8288;054 trial. <i>J Clin Oncol.</i> 2020;38(33):3925–3936."
    },
    {
        label: "17.",
        text: "Gershenwald JE, Scolyer RA, Hess KR, et al. Melanoma of the skin. In: AJCC Cancer Staging Manual. 8th ed. American Joint Committee on Cancer; 2017:563–585."
    },
]
const patientProfileFrank = {
    image: () => (
        <StaticImage layout="fixed" src="../../assets/patient-frank.png" placeholder="TRACED_SVG" alt="" width={100} height={100} />
    ),
    name: "FRANK",
    diagnosis: "STAGE IIB (T4aN0M0) MELANOMA<sup>17</sup>",
    primaryCharacteristics: [
        {
            key: 'Presentation',
            value: '• Presented with an asymmetric lesion on his left shoulder.<br/>• Punch biopsy results:<br/>4.7-mm-thick superficial spreading melanoma; non-ulcerated',
            emphasize: false,
        },
        {
            key: 'Sentinel Lymph Node Biopsy Results',
            value: 'Negative',
            emphasize: false,
        },
        {
            key: 'Wide Local Excision Results',
            value: 'Margins clear',
            emphasize: false,
        },
        {
            key: 'ECOG PS',
            value: '0',
            emphasize: false,
        },
             
    ],
    secondaryCharacteristics: [
       
        {
            key: 'Age',
            value: '62',
        },
        {
            key: 'Gender',
            value: 'Male',
        },
       
    ],
}
const patientProfileMariel = {
    image: () => (
        <StaticImage layout="fixed" src="../../assets/patient-mariel.png" placeholder="TRACED_SVG" alt="" width={100} height={100} />
    ),
    name: "MARIEL",
    diagnosis: "STAGE IIIA (T2aN1aM0) MELANOMA<sup>17</sup>",
    primaryCharacteristics: [
        {
            key: 'Presentation',
            value: '• Noticed 2 new small skin lesions during her regular self-examination.<br/>• Punch biopsy results:<br/>–1.2-mm-thick superficial spreading melanoma; non-ulcerated<br/>–1.3-mm-thick superficial spreading melanoma; non-ulcerated',
            emphasize: false,
        },
        {
            key: 'Sentinel Lymph Node Biopsy Results',
            value: 'Node positive (1/2 nodes; >1-mm metastatic focus within node)',
            emphasize: false,
        },
        {
            key: 'Wide Local Excision',
            value: 'Margins clear',
            emphasize: false,
        },
        {
            key: 'Biomarker Testing Results',
            value: '<i>BRAF</i> wild type; PD-L1 not tested',
            emphasize: false,
        },
        {
            key: 'PET/CT Results',
            value: 'No additional local or distant metastasis',
            emphasize: false,
        },
        {
            key: 'ECOG PS',
            value: '0',
            emphasize: false,
        },
             
    ],
    secondaryCharacteristics: [
       
        {
            key: 'Age',
            value: '39',
        },
        {
            key: 'Gender',
            value: 'Female',
        },
       
    ],
}
const Page = ({ location }) => {
    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={pageMeta} location={location}>
            <div data-template-name="template-e">

                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Clinical Trial Results" />
                    <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId} />
                        <AnchorLinks items={anchorLinkModelData} />
                    </PageSection>
                    <PageSection bgColor="gradient">
                        <PrimaryClaim theme="light">An anti–PD&#8288;-&#8288;1 adjuvant therapy approved for completely resected stage IIB and stage IIC melanoma.<sup>1</sup></PrimaryClaim>
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                    {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">

                        <PageSection bgColor="cloud" title={anchorLinkModelData[0].label}>
                            <PageSubSection title="Significant Improvement in Recurrence-Free Survival (RFS) Demonstrated With KEYTRUDA vs Placebo">
                                <SecondaryCallout 
                                    title="For adjuvant treatment in patients (12 years and older) with completely resected stage IIB or stage IIC melanoma"
                                    alt=""
                                />
                                <FeaturedStat
                                    statistic="35%"
                                    title="Reduction in the risk of disease recurrence or death with KEYTRUDA compared with placebo<br><br>HR=0.65 (95% CI, 0.46–0.92; <i>P</i>=0.0132)"
                                />
                                <ComparisonTable
                                    title="Initial analysis of RFS in KEYNOTE&#8288;-&#8288;716 for KEYTRUDA at a 14.4-Month Median Follow-Up vs 14.3 Months for Placebo<sup>2</sup>"
                                    data={
                                        [
                                            [
                                                {
                                                    legendTitle: 'KEYTRUDA (n=487)',
                                                },
                                                {
                                                    legendTitle: 'Placebo (n=489)',
                                                },
                                            ],
                                            [
                                                {
                                                    colspan: 2,
                                                    centerText: true,
                                                    hrCiP: 'HR<sup>a,b</sup>=0.65 (95% CI, 0.46–0.92; <i>P</i><sup>b</sup>=0.0132<sup>c</sup>)'
                                                }
                                            ],
                                            [
                                                {
                                                    label: 'Events observed',
                                                    dataPoint: '54 (11%)',
                                                },
                                                {
                                                    label: 'Events observed',
                                                    dataPoint: '82 (17%)',
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Median in Months',
                                                    dataPoint: 'NR',
                                                    dataSubPoint: '(95% CI, 22.6–NR)', 
                                                },
                                                {
                                                    label: 'Median in Months',
                                                    dataPoint: 'NR',
                                                    dataSubPoint: '(95% CI, NR–NR)',
                                                },
                                            ],
                                        ]
                                    }
                                    footnotes={kn716InterimOneTableFootnotes}
                                    definitions="HR = hazard ratio; CI = confidence interval; AJCC = American Joint Committee on Cancer; NR = not reached."
                                />
                                <KMCurve
                                    title="Post hoc RFS Analysis from KEYNOTE&#8288;-&#8288;716 at a 52.8-Month Median Follow-Up<sup>3,4</sup>"
                                    hr="HR<sup>d</sup>=0.62 (95% CI<sup>d</sup>, 0.50&ndash;0.78)"
                                    limitation="<b>LIMITATION:</b> No formal statistical testing was planned for the post hoc analysis for RFS that occurred at a median follow-up of 52.8 months and, therefore, no conclusions can be drawn."
                                    additionalInformation={[
                                        {
                                            title: 'Additional Information',
                                            text: `
                                                <p>Post hoc RFS analysis for KEYNOTE&#8288;-&#8288;716 at a 52.8-month median follow up:</p>     
                                                <ul>
                                                    <li>The RFS hazard ratio<sup>d</sup> was 0.62 (95% CI<sup>d</sup>, 0.50&ndash;0.78).</li>
                                                    <li>134/487 (27.5%) of patients in the KEYTRUDA arm and 198/489 (40.5%) of patients in the placebo arm experienced RFS events.</li>
                                                    <li>Median RFS was not reached with KEYTRUDA (95% CI, NR–NR) and was 59.9 months with placebo.</li>
                                                    <li>According to Kaplan-Meier estimates, the 4-year RFS rates were 71% with KEYTRUDA and 58% with placebo.</li>
                                                </ul>
                                            `
                                        },
                                    ]}
                                    footnotes={kn716KMCurveFootnotes}
                                    image={() => <StaticImage 
                                        src="../../assets/KN716_RFS_KM_Curve.png"
                                        placeholder="none"
                                        alt="Kaplan-Meier Estimates of Recurrence-Free Survival (RFS) With KEYTRUDA® (pembrolizumab) vs Placebo in KEYNOTE-716" />}
                                />
                            </PageSubSection>
                            <PageSubSection title="KEYTRUDA Significantly Improved Distant Metastasis-Free Survival<sup>e</sup> (DMFS) vs Placebo in Patients With Completely Resected Stage IIB or IIC Melanoma">
                                <SecondaryCallout title="KEYTRUDA significantly reduced the risk of completely resected stage IIB or IIC melanoma returning as stage IV disease<sup>5,f</sup> by 36% vs placebo (HR<sup>g,h</sup>=0.64; 95% CI, 0.47-0.88; <i>P</i><sup>g</sup>=0.0058<sup>i</sup>)"/>
                                <ComparisonTable
                                    title="Initial Analysis of DMFS, an Additional Efficacy Outcome Measure, in KEYNOTE&#8288;-&#8288;716 at a 27.4-Month Median Follow-Up<sup>6</sup>"
                                    data={
                                        [
                                            [
                                                {
                                                    legendTitle: 'KEYTRUDA (n=487)',
                                                },
                                                {
                                                    legendTitle: 'Placebo (n=489)',
                                                },
                                            ],
                                            [
                                                {
                                                    colspan: 2,
                                                    centerText: true,
                                                    hrCiP: 'HR<sup>g,h</sup>=0.64; (95% CI, 0.47–0.88; <i>P</i><sup>h</sup>=0.0058<sup>i</sup>)'
                                                }
                                            ],
                                            [
                                                {
                                                    label: 'Events observed',
                                                    dataPoint: '63 (13%)',
                                                },
                                                {
                                                    label: 'Events observed',
                                                    dataPoint: '95 (19%)',
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Median in Months',
                                                    dataPoint: "NR",
                                                    dataSubPoint: '(95% CI, NR–NR)',
                                                },
                                                {
                                                    label: 'Median in Months',
                                                    dataPoint: "NR",
                                                    dataSubPoint: '(95% CI, NR–NR)',
                                                },
                                            ],
                                        ]
                                    }
                                    footnotes={kn716DmfsTableFootnotes}
                                />
                                <KMCurve
                                    title="Post hoc Analysis: Kaplan-Meier Estimates of DMFS With KEYTRUDA and Placebo at a 52.8-Month Median Follow-Up in KEYNOTE&#8288;-&#8288;716<sup>3,4</sup>"
                                    limitation="<b>LIMITATION:</b> No formal statistical testing was planned for the post hoc analysis for  DMFS that occurred at a median follow-up of 52.8 months and, therefore, no conclusions can be drawn."
                                    hr='HR<sup>j</sup>=0.59 (95% CI, 0.45&ndash;0.77)'
                                    image={() => <StaticImage src="../../assets/KN716_DMFS_KM_Curve.png" placeholder="none" alt="Kaplan-Meier Estimates of Distant Metastasis-Free Survival (DMFS) in KEYNOTE-716" />}
                                    footnotes={kn716DmfsKMFootnotes}
                                    additionalInformation={[
                                        {
                                            title: "Additional Information",
                                            text: `
                                                <p>Post hoc DMFS analysis for KEYNOTE⁠-⁠716 at a 52.8-month median follow up:</p>
                                                <ul>
                                                    <li>The DMFS hazard ratio was 0.59<sup>j</sup> (95% CI, 0.45&ndash;0.77).</li>
                                                    <li>86/487 (17.7%) of patients in the KEYTRUDA arm and 137/489 (28.0%) of patients in the placebo arm experienced DMFS events.</li>
                                                    <li>Median DMFS was not reached in either arm.</li>
                                                    <li>According to Kaplan-Meier estimates, the 4-year DMFS rates were 81% with KEYTRUDA and 70% with placebo.</li>
                                                </ul>
                                            `,
                                        }
                                    ]}
                                />
                            </PageSubSection>
                            
                            <PageSubSection title={anchorLinkModelData[1].label}>
                                <MEL_ADJUVANT_2 />
                            </PageSubSection>
                        </PageSection>

                        <PageSection title={anchorLinkModelData[2].label}>
                            <TextBlock>
                                <h6>Data from a Retrospective Study: Risk of Recurrence for Completely Resected Stage IIB and IIC Melanoma<sup>8</sup></h6>
                                <p><b>Study Design:</b> This study was a retrospective chart review of 567 adult patients who were followed for a median of 38.8 months after complete resection of stage IIB or IIC cutaneous melanoma between 2008 and 2017. Patients in this study had at least 2 visits after diagnosis recorded within the US Oncology Network, a community-based network of over 480 cancer centers in 25 states. Eighty (14.1%) patients in the study received adjuvant treatment for stage IIB or IIC melanoma; of those who received adjuvant treatment, 77 (96.3%) received IFN-alpha. Recurrence in this study was captured as physician-documented recurrence or progression.</p>
                            </TextBlock>
                            <ComparisonTable
                                title="Rates of Recurrence<sup>8</sup>"
                                data={[
                                    [
                                        {
                                            legendTitle: 'Resected Stage IIB Melanoma',
                                        },
                                        {
                                            legendTitle: 'Resected Stage IIC Melanoma',
                                        },
                                    ],
                                    [
                                        {
                                            label: 'Rate of Recurrence',
                                            dataPoint: '37%',
                                            dataSubPoint: '(n=140/375)',
                                        },
                                        {
                                            label: 'Rate of Recurrence',
                                            dataPoint: '43%',
                                            dataSubPoint: '(n=83/192)',
                                        },
                                    ],
                                    [
                                        {
                                            label: 'Of the 140 patients with recurrence, distant metastasis was the first recurrence in',
                                            dataPoint: '50%',
                                            dataSubPoint: '(n=70/140)',
                                        },
                                        {
                                            label: 'Of the 83 patients with recurrence, distant metastasis was the first recurrence in',
                                            dataPoint: '58%',
                                            dataSubPoint: '(n=48/83)',
                                        },
                                    ],
                                ]}
                                footnotes={kn716RORTableFootnotes}
                                additionalInformation={[
                                    {
                                        title: "Additional Information",
                                        text: `
                                            <p>Among these patients, sites of distant metastasis included lung, distant lymph nodes, brain, liver, bone, skin, peritoneum, muscle, and other.<sup>8,k</sup></p>
                                        `,
                                    }
                                ]}
                                definitions={"IFN-alpha = interferon-alpha."}
                            />
                                           
                        </PageSection>

                        <PageSection title={anchorLinkModelData[3].label} bgColor='cloud' >
                            <NCCNcallout
                                data={NCCNcalloutData}
                                footnotes={NCCNFootnotes}
                                definitions={NCCNdefinitions}
                                references={NCCNcalloutReferences}
                            />
                        </PageSection>

                        {/* BEGIN KEYNOTE-054 SECTION */}

                        <PageSection title={anchorLinkModelData[4].label}>
                            <PageSubSection title="Significant Improvement in Recurrence-Free Survival (RFS) Demonstrated With KEYTRUDA vs Placebo">
                                <SecondaryCallout 
                                    title="For adjuvant treatment in patients (12 years and older) with completely resected stage III melanoma" 
                                    alt=""
                                />
                                <ComparisonTable
                                    title="Initial Analysis of RFS in KEYNOTE&#8288;-&#8288;054 for KEYTRUDA vs Placebo at a 15.1-Month Median Follow-Up<sup>10</sup>"
                                    data={
                                        [
                                            [
                                            {
                                                legendTitle: 'KEYTRUDA (n=514)',
                                            },
                                            {
                                                legendTitle: 'Placebo (n=505)',
                                            },
                                        ],
                                        [
                                            {
                                                colspan: 2,
                                                centerText: true,
                                                hrCiP: 'HR<sup>p,q</sup>=0.57 (95% CI, 0.46–0.70; <i>P</i><sup>q</sup><0.001<sup>r</sup>)'
                                            }
                                        ],
                                        [
                                            {
                                                label: 'Events observed',
                                                dataPoint: '135 (26%)',
                                            },
                                            {
                                                label: 'Events observed',
                                                dataPoint: '216 (43%)',
                                            },
                                        ],
                                        [
                                            {
                                                label: 'Median in Months',
                                                dataPoint: 'NR',
                                            },
                                            {
                                                label: 'Median in Months',
                                                dataPoint: '20.4',
                                                dataSubPoint: '(95% CI, 16.2–NR)',
                                            },
                                        ],
                                    ]
                                        }
                                        footnotes={kn054RFSTableFootnotes}
                                />
                                <FeaturedStat 
                                    statistic="43%"
                                    title="Reduction in the risk of disease recurrence or death with KEYTRUDA compared with placebo<br><br>HR=0.57 (95% CI, 0.46–0.70; <i>P</i><0.001)"
                                />
                                <KMCurve
                                    title="5-Year RFS Analysis in KEYNOTE&#8288;-&#8288;054: Kaplan-Meier Estimates With KEYTRUDA and Placebo at a 4.9-Year Median Follow-Up<sup>11,12</sup>"
                                    hr='HR<sup>s</sup>=0.61 (95% CI, 0.51–0.72)'
                                    limitation="<b>LIMITATION:</b> No formal statistical testing was planned for the post hoc analysis of RFS that occurred at a median follow-up of 4.9 years and, therefore, no conclusions can be drawn."
                                    additionalInformation={[
                                        {
                                            title: 'Additional Information',
                                            text: `
                                            <p>Updated RFS analysis from KEYNOTE⁠&#8288;-&#8288;054 at a 4.9-year median follow up:</p>
                                            <ul>
                                                <li>The RFS hazard ratio<sup>s,v</sup> was 0.61.</li>
                                                <li>228/514 (44.4%) of patients in the KEYTRUDA arm and 304/505 (60.2%) of patients in the placebo arm experienced RFS events.</li>
                                                <li>Median RFS was 67.4 months in the KEYTRUDA arm and 21.2 months in the placebo arm.</li>
                                            </ul>
                                            `
                                        },
                                    ]}
                                    footnotes={kn054RFSKMFootnotes}
                                    definitions='Placebo = complete surgical resection followed by observation.'
                                    image={() => <StaticImage src="../../assets/mel-adjuvant-054-rfs-km-curve-v3.png"
                                        placeholder="none"
                                        alt="5-Year Recurrence-Free Survival (RFS) Analysis in KEYNOTE⁠-⁠054" />
                                    }
                                />
                            </PageSubSection>

                            <PageSubSection title="KEYTRUDA Significantly Improved Distant Metastasis-Free Survival<sup>t</sup> (DMFS) vs Placebo in Patients With Completely Resected Stage III Melanoma">
                            <SecondaryCallout 
                                title="KEYTRUDA significantly reduced the risk of completely resected stage III melanoma returning as stage IV disease<sup>5,u</sup> or death vs placebo (HR<sup>v,w</sup>=0.60; 95% CI, 0.49&ndash;0.73; <i>P</i><0.0001<sup>x,y</sup>)" 
                            />
                            <ComparisonTable
                                    title="Initial Analysis of DMFS, an Additional Efficacy Outcome Measure, in KEYNOTE&#8288;-&#8288;054 at a 42.3-Month Median Follow-Up<sup>13</sup>"
                                    data={
                                        [
                                            [
                                                {
                                                    legendTitle: 'KEYTRUDA (n=514)',
                                                },
                                                {
                                                    legendTitle: 'Placebo (n=505)',
                                                },
                                            ],
                                            [
                                                {
                                                    colspan: 2,
                                                    centerText: true,
                                                    hrCiP: 'HR<sup>v,w</sup>=0.60 (95% CI, 0.49–0.73; <i>P</i><sup>w</sup><0.0001<sup>x,y</sup>)'
                                                }
                                            ],
                                            [
                                                {
                                                    label: 'Events observed',
                                                    dataPoint: '173 (34%)',
                                                },
                                                {
                                                    label: 'Events observed',
                                                    dataPoint: '245 (49%)',
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Median in Months',
                                                    dataPoint: "NR",
                                                    dataSubPoint: '(95% CI, 49.6–NR)',
                                                },
                                                {
                                                    label: 'Median in Months',
                                                    dataPoint: "40.0",
                                                    dataSubPoint: '(95% CI, 27.7&ndash;NR)',
                                                },
                                            ],
                                        ]
                                    }
                                />
                                <KMCurve
                                    title="Initial Analysis (ITT Population): Kaplan-Meier Estimates of DMFS With KEYTRUDA vs Placebo at a 42.3-Month Median Follow-Up in KEYNOTE&#8288;-&#8288;054<sup>13</sup>"
                                    hr="HR<sup>v,w</sup>=0.60 (95% CI, 0.49–0.73; <i>P</i><sup>w</sup><0.0001<sup>x,y</sup>)"
                                    image={() => <StaticImage src="../../assets/mel-adjuvant-054-dmfs-km-curve-v2.png" placeholder="none" alt="Kaplan-Meier Estimates of Distant Metastasis-Free Survival (DMFS) With KEYTRUDA® (pembrolizumab) vs Placebo in KEYNOTE⁠-⁠054" />}
                                    definitions="ITT = intent-to-treat."
                                    footnotes={kn054DMFSKMFootnotes}
                                />
                                
                            </PageSubSection>
            
                            <SecondaryCallout
                                title="Efficacy demonstrated regardless of PD&#8288;-&#8288;L1 status"
                                text={`
                                    <p>For patients with PD&#8288;-&#8288;L1 positive tumors, the RFS HR was 0.54 (95% CI, 0.42–0.69); <i>P</i><0.0001.</p>
                                    <p>For patients with PD&#8288;-&#8288;L1 positive tumors, the DMFS HR was 0.61 (95% CI, 0.49&ndash;0.76); <i>P</i><0.0001.</p>
                                    <p>The RFS and DMFS benefit for KEYTRUDA compared with placebo was observed regardless of tumor PD&#8288;-&#8288;L1 expression.</p>`
                                }
                                definitions="PD&#8288;-&#8288;L1 = programmed death ligand 1; HR = hazard ratio; CI = confidence interval."
                                alt=""
                            />
                        </PageSection>

                        <PageSection title={anchorLinkModelData[5].label} bgColor="cloud">
                            <MEL_ADJUVANT />
                        </PageSection>

                        <PageSection title={anchorLinkModelData[6].label}>
                            {/* Revisit this to create a proper image-based graph component and/or revive the bar chart component */}
                            <TextBlock>
                                <h4>Recurrence Rates at 3-Year Analysis in Patients With Stage III Melanoma Who Received Placebo in KEYNOTE&#8288;-&#8288;054<sup>16</sup></h4>
                                <p>Data shown are for patients with melanoma with involvement of lymph node(s) following complete resection.</p>
                                <p><b>LIMITATION:</b> KEYNOTE&#8288;-&#8288;054 was not powered to measure rate of recurrence at the 3-year analysis for patients who received placebo or to detect differences in the treatment effect between substages. Therefore, no conclusions can be drawn.</p>
                            </TextBlock>
                            <DataTable
                                mobileImage={() => <img src={recurrenceTable} style={{width:'100%', maxWidth:'500px', margin: '0 auto'}} alt={recurrenceTableAlt}/>}
                                desktopImage={() => <StaticImage quality={100} placeholder="DOMINANT_COLOR" src="../../assets/MELA-217379_ROR-Concept_chart_L18-01_desktop.jpg" alt={recurrenceTableAlt}/>}
                                footnotes={recurrenceTableFootnotes} 
                                additionalInformation={[
                                    {
                                        title: 'Additional Information',
                                        text: `
                                        <p>Recurrence in the KEYNOTE&#8288;-&#8288;054 trial included local, regional, and distant recurrences, as well as death from any cause.</p>
                                        <p>Patients enrolled in KEYNOTE&#8288;-&#8288;054 had stage IIIA (>1mm lymph node metastasis), stage IIIB, or stage IIIC melanoma according to AJCC, 7th ed.</p>
                                        <p>Median follow-up time for 3-year analysis was 36.6 months.</p>
                                        `
                                    },
                                ]}
                            />
                        </PageSection>
                        <PageSection title={anchorLinkModelData[7].label}>
                        <Heading.H3
                                as='h3'
                                dangerouslySetInnerHTML={createMarkup("Based on Those Studied in KEYNOTE-716 and KEYNOTE-054")}
                            />
                            {/* <h3 className="Heading__H3-sc-1aw7hbk-2 ibdZKs"></h3> */}
                               
                        <PatientProfile {...patientProfileFrank} />
                            </PageSection>
                        <PageSection>
                        <PatientProfile {...patientProfileMariel} definitions="PET/CT = positron emission tomography/computed tomography." />
                            </PageSection>
                    </TemplateColumn>

                    {/* End Main Column */}
                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent} />
                    </PageSection>
                    <PageSection bgColor="mist">
                        <ReferencesBlock references={pageReferences} />
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
